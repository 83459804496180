import React from 'react';
import PropTypes from 'prop-types';
import {
  renderText,
  getCaretCharacterOffsetWithin,
  getTextFromNode,
  setCaretToPosition
} from './Utils';

class PersonalizedText extends React.Component {
  componentDidMount() {
    this.node.contentEditable = !this.props.disabled;
    this.node.addEventListener('input', this.handleChange, false);
    this.renderContent();
  }
  shouldComponentUpdate(nextProps) {
    return this.props.disabled || this.props.disabled !== nextProps.disabled;
  }
  componentDidUpdate(prevProps) {
    const disabledModeChanged = this.props.disabled !== prevProps.disabled;
    const textChanged = prevProps.text !== this.props.text;

    if (disabledModeChanged || (this.props.disabled && textChanged)) {
      this.renderContent();
    }
  }

  handleChange = () => {
    const { onChange } = this.props;
    onChange && onChange(getTextFromNode(this.node));
  };
  renderContent() {
    const offset = getCaretCharacterOffsetWithin(this.node);
    const { snippets } = this.props;
    const text = getTextFromNode(this.node);
    this.node.innerHTML = renderText(text, snippets);
    if (document.activeElement === this.node) {
      setCaretToPosition(this.node, offset + 1);
    }

    if (!this.props.disabled) {
      [...this.node.getElementsByClassName('attribute')].forEach(attribute => {
        attribute.addEventListener('dragstart', e => {
          e.dataTransfer.setData('text/plain', attribute.dataset.snippet);
          this.dragSnippet = attribute;
        });
      });
    }
  }
  handleDrop = () => {
    if (this.props.disabled) {
      return;
    }

    if (this.dragSnippet) {
      this.dragSnippet.remove();
      delete this.dragSnippet;
    }
    setTimeout(() => this.renderContent());
  };
  render() {
    const { text, disabled, placeholder } = this.props;
    return (
      <div
        className={`snippets-text ${disabled ? 'disabled' : true}`}
        ref={ref => (this.node = ref)}
        onDrop={this.handleDrop}
        placeholder={placeholder}
      >
        {text}
      </div>
    );
  }
}

PersonalizedText.propTypes = {
  text: PropTypes.string.isRequired,
  snippets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      snippet: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

export default PersonalizedText;
