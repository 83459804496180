import request from './request';
import { limit, merge, toSearchQuery } from './helpers';

export default {
  listTypes: () => request.get('/merchant-type'),
  updatePersonalInfo: info => request.put('/merchant/personal-info', info),
  addTemplateToFavorites: itemId =>
    request.post('/merchant/favourites/template', { itemId }),
  removeTemplateFromFavorites: itemId =>
    request.delWithBody('/merchant/favourites/template', { itemId }),
  addMessageToFavorites: itemId =>
    request.post('/merchant/favourites/message', { itemId }),
  removeMessagesFromFavorites: ids =>
    request.delWithBody('/merchant/favourites/messages', { ids }),
  listFavouriteMessages: (page, limitBy, type, searchTerm) =>
    request.get(
      `/merchant/favourites/message?${merge(
        limit(limitBy, page),
        toSearchQuery({ type, searchTerm })
      )}`
    ),
  getPayments: (page, limitBy = 10) =>
    request.get(`/merchant/payments?${limit(limitBy, page)}`)
};
