import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VideoPlayer from 'Components/VideoPlayer';

import {
  SnippetsBar,
  PersonalizedText
} from 'Components/PersonalizedTextEditor';

import SNIPPETS from 'Pages/Builder/CustomerSnippets';

import './landing.css';

class LandingPage extends Component {
  static propTypes = {
    logo: PropTypes.string,
    title: PropTypes.string,
    landingText: PropTypes.string,
    videoUrl: PropTypes.string,
    videoThumbnailUrl: PropTypes.string,
    videoOverlay: PropTypes.object,
    onTextChange: PropTypes.func,
    onTitleChange: PropTypes.func,
    onEdit: PropTypes.func,
    onReRecord: PropTypes.func,
    frameSource: PropTypes.string,
    videoFromCuratedList: PropTypes.bool
  };

  state = {};
  handleVideoResize = width => {
    this.setState({ currentVideoWidth: width });
  };
  handleVideoPropsReceived = ({ width, height }) => {
    this.setState({ videoFormat: width / height });
  };

  handleChange = handler => ({ target }) => handler && handler(target.value);

  render() {
    const {
      logo,
      onEdit,
      onReRecord,
      onTextChange,
      videoUrl,
      videoOverlay,
      videoThumbnailUrl,
      onTitleChange,
      title,
      landingText,
      frameSource,
      videoRecordedBy,
      videoFromCuratedList
    } = this.props;

    const { currentVideoWidth, videoFormat } = this.state;
    const videoHeight =
      currentVideoWidth && videoFormat ? currentVideoWidth / videoFormat : null;

    return (
      <div className="landing-page-main-component message">
        <div>
          <div className="message__element">
            <img src={logo} className="landing-page-logo" alt="logo" />
          </div>
          <div style={{ padding: 10, textAlign: 'center' }}>
            <SnippetsBar snippets={SNIPPETS} />
          </div>

          <div className="message__element">
            <div className="message__title">
              <PersonalizedText
                text={title}
                placeholder="<enter landing page title>"
                snippets={SNIPPETS}
                onChange={value => onTitleChange && onTitleChange(value)}
              />
            </div>
          </div>

          {videoUrl && (
            <div
              className="message__element video-element"
              style={{
                margin: '0 auto 50px auto',
                maxWidth: 560,
                width: '100',
                height: videoHeight || 315
              }}
            >
              <VideoPlayer
                recordedBy={videoRecordedBy}
                overlay={videoOverlay}
                videoSrc={[`${videoUrl}.mp4`, videoUrl]}
                onVideoPropsReceived={this.handleVideoPropsReceived}
                onResize={this.handleVideoResize}
                frameSource={frameSource}
                poster={videoThumbnailUrl}
              />

              {!videoFromCuratedList && (
                <div className="video-link-buttons">
                  <span onClick={onEdit}>Edit</span>
                  <span onClick={onReRecord}>Re-Record</span>
                </div>
              )}
            </div>
          )}

          <div className="message__element">
            <div className="message__text">
              <PersonalizedText
                text={landingText}
                placeholder="<enter landing page text>"
                snippets={SNIPPETS}
                onChange={value => onTextChange && onTextChange(value)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingPage;
