import React from 'react';
import PropTypes from 'prop-types';
import Snippet from './Snippet';
import './SnippetsBar.css';

const SnippetsBar = ({ snippets }) => {
  return (
    <div className="snippets-bar">
      {snippets.map(({ snippet, title }) => (
        <Snippet key={snippet} {...{ snippet, title }} />
      ))}
    </div>
  );
};

SnippetsBar.propTypes = {
  snippets: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      snippet: PropTypes.string.isRequired
    })
  ).isRequired
};

export default SnippetsBar;
